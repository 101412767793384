<template>
  <div class="task-detail">
    <div class="task-header">
      <div class="task-header-text">
        <h4 class="task-title">{{ taskDetail.name }}</h4>
        <van-count-down
          v-if="taskDetail.status == 0 && endTime > 0"
          :time="endTime"
          @finish="taskDetail.status = 2"
        >
          <template #default="timeData">
            <!-- {{ endTime }} -->
            <span class="task-time">距离任务结束剩余&nbsp;</span>
            <span v-if="timeData.days">
              <span class="txt-blue weight500">{{ timeData.days }}</span>
              <span class="task-time">天&nbsp;</span>
            </span>
            <span v-if="timeData.hours">
              <span class="txt-blue weight500">{{ timeData.hours }}</span>
              <span class="task-time">时&nbsp;</span>
            </span>
            <span v-if="timeData.minutes">
              <span class="txt-blue weight500">{{ timeData.minutes }}</span>
              <span class="task-time">分&nbsp;</span>
            </span>
            <span v-if="timeData.seconds">
              <span class="txt-blue weight500">{{ timeData.seconds }}</span>
              <span class="task-time">秒</span>
            </span>
          </template>
        </van-count-down>
        <div v-else class="task-time">结束时间：{{ taskDetail.end_time }}</div>
      </div>
      <div class="hearder-operation">
        <!-- <div
          class="send-btn"
          v-if="taskDetail.status == 0 && endTime > 0"
          @click.stop="onSend"
        >
          <img
            class="btn-icon"
            src="@/assets/img/suggestion/share.svg"
            alt="share"
          />
          <span class="btn-text">发送</span>
        </div> -->
        <div class="status-text" v-if="taskDetail.status != 0 || endTime == 0">
          {{ statusFormat(taskDetail.status) }}
        </div>
      </div>
    </div>
    <div class="task-detail-content pb70">
      <div class="content-text">
        {{ taskDetail.content }}
      </div>
      <div v-if="mediaList[0]" class="media-detail-box">
        <MediaGride
          :detail="taskDetail"
          :mediaList="mediaList"
          pageType="task"
        ></MediaGride>
      </div>
    </div>
    <div class="bottom-btn-box" v-if="type != 'dynamicList'">
      <div class="send-btn" @click="onSend">
        <img
          class="send-icon"
          src="@/assets/img/suggestion/share.svg"
          alt="分享"
        />
        <div class="send-text">发送</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, ImagePreview, Image as VanImage, CountDown } from 'vant'
// import mediaGride from '@/components/detail/mediaGride.vue'
import MediaGride from '../../../../components/detail/mediaGride.vue'
import {
  getMarketingTaskDetail,
  backTaskStatus,
  sendMonitor
} from '../../../../service/quickSend.service.js'
// import VideoPlayer from './VideoPlayer.vue'
import {
  openMiniProgram,
  sendChatMessageAllType,
  openNewWindow,
  previewFile,
  getCurExternalContact
} from '../../../../utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'

export default {
  components: {
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
    [ImagePreview.Component.name]: ImagePreview.Component,
    MediaGride: MediaGride
    // VideoPlayer: VideoPlayer
  },
  data() {
    return {
      endTime: 0,
      params: {
        record_id: '',
        task_id: ''
      },
      customer_userid: '', // 客户id
      member_userid: '', // 成员id
      taskDetail: {
        annex_content_json: []
      },
      mediaList: [],
      mediaListSend: [],
      currentVideo: '',
      playerShow: false,
      taskStatus: false,
      lackNum: 0,
      share_data_id: '',
      type: '' // 判断是否从动态列表过来，如果是，不需要出现发送按钮让其再次发送
    }
  },
  async mounted() {
    // 从地址栏获取任务id
    this.params.record_id = this.$route.query.id || ''
    this.params.task_id = this.$route.query.task_id || ''
    this.type = this.$route.query.type
    // 成员id
    let userInfo = JSON.parse(localStorage.getItem('vuex'))
    this.member_userid = userInfo.user.info.qw_userid
    await this.getCustomerId()
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      const { data, code } = await getMarketingTaskDetail({
        ...this.params,
        external_userid: this.customer_userid
      })
      if (code == 200) {
        this.taskDetail = data || {}
        this.mediaList = JSON.parse(JSON.stringify(data.annex_content_json))
        this.mediaListSend = JSON.parse(JSON.stringify(data.annex_content_json))
        // console.log('获取到详情页数据', this.taskDetail, this.mediaList)
        // 计算缺少数量
        // this.getlackNum()
        // 结束倒计时
        this.endTime = this.taskDetail.end_time_strtotime * 1000 - new Date()
      }
    },

    // 获取客户id
    async getCustomerId() {
      // 客户id
      this.customer_userid = await getCurExternalContact()
    },
    // 发送前的埋点回调
    async beforeSend() {
      let { data, code } = await sendMonitor({
        payable_id: this.params.task_id,
        type: 'task',
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    async onSend() {
      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        if (this.taskDetail.content) {
          // console.log('发送文字消息')
          let { sendStatus } = await sendChatMessageAllType(
            'text',
            {
              text: { content: this.taskDetail.content }
            },
            false
          )
          // console.log('发送状态：-----------：', sendStatus)
          // 当所有任务中有一条分享成功，这个任务即被完成，所以当taskStatus为true时不再改变
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }
        // this.taskDetail.annex_content_json.forEach((item) => {
        let mediaList = this.mediaListSend
        for (let i = 0; i < mediaList.length; i++) {
          // console.log('测试发送问题', i, mediaList[i].type)
          if (mediaList[i].type == 'images' && mediaList[i].media_id) {
            let { sendStatus } = await sendChatMessageAllType(
              'image',
              {
                image: { mediaid: mediaList[i].media_id }
              },
              false
            )
            // console.log('发送状态：-----------：', sendStatus)
            this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
          } else if (mediaList[i].type == 'video' && mediaList[i].media_id) {
            let { sendStatus } = await sendChatMessageAllType(
              'video',
              {
                video: { mediaid: mediaList[i].media_id }
              },
              false
            )
            // console.log('发送状态：-----------：', sendStatus)
            this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
          } else if (mediaList[i].type == 'web_page') {
            let { sendStatus } = await sendChatMessageAllType(
              'news',
              {
                news: {
                  link: mediaList[i].url, //H5消息页面url 必填
                  title: mediaList[i].title, //H5消息标题
                  desc: mediaList[i].description, //H5消息摘要
                  imgUrl: mediaList[i].upload_url //H5消息封面图片URL
                }
              },
              false
            )
            // console.log('发送状态：-----------：', sendStatus)
            this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
          } else if (
            mediaList[i].type == 'we_applets' ||
            mediaList[i].type == 'commodity' ||
            mediaList[i].type == 'coupon' ||
            mediaList[i].type === 'activity'
          ) {
            const data = mediaList[i]
            let path = data.url
            const pathIndex = path.indexOf('?')
            if (pathIndex === -1) {
              if (path.indexOf('.html') === -1) {
                path = `${path}.html`
              }
              path = `${path}?task_id=${this.params.task_id}`
              // 商品附件
              if (data.goods_id) path = `${path}&goods_id=${data.goods_id}`
              // 优惠券附件
              if (data.coupon_id) path = `${path}&coupon_id=${data.coupon_id}`
            } else {
              if (path.indexOf('.html') === -1) {
                let pathArray = path.split('')
                const index = pathArray.indexOf('?')
                pathArray.splice(index, 0, '.html')
                path = pathArray.join('')
              }
              path = `${path}&task_id=${this.params.task_id}`
              // 商品附件
              if (data.goods_id) path = `${path}&goods_id=${data.goods_id}`
              // 优惠券附件
              if (data.coupon_id) path = `${path}&coupon_id=${data.coupon_id}`
            }
            // 分配相关参数添加
            path = await formatParamsDistribution(
              path,
              this.customer_userid,
              this.chat_id,
              'task'
            )
            // 如果存在埋点id加入埋点id
            if (this.share_data_id) {
              path = `${path}&share_data_id=${this.share_data_id}`
            }
            let { sendStatus } = await sendChatMessageAllType(
              'miniprogram',
              {
                miniprogram: {
                  appid: data.appid, //小程序的appid
                  title: data.title, //小程序消息的title
                  imgUrl: data.upload_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                  page: path //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
                }
              },
              false
            )
            // console.log('发送状态：-----------：', sendStatus)fff/
            this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
          }
        }
      } catch (e) {
        console.log(e)
      }
      if (this.taskStatus) {
        // 回传改变状态
        await backTaskStatus({
          send_record_son_id: this.taskDetail.task_send_record_son_id,
          member_userid: this.member_userid,
          external_userid: this.customer_userid,
          send_status: this.taskStatus ? 'success' : 'fail'
        }).then((res) => {
          // console.log('回传参数---', res)
          if (res.code == 200) {
            this.taskDetail.status = 1
            this.$router.go(-1)
          }
        })

        // 发送完成后关闭当前窗口
        // window.location.href = 'about:blank'
        // window.close()
        wx.closeWindow()
      }
    },

    // 处理小程序路径加上分配所需参数
    formatParamsDistribution(path) {
      let obj = {
        member_id: localStorage.getItem('member_id') || '',
        customer_id: this.customer_userid || '',
        chart_id: this.chat_id || '',
        source: 'label'
      }
      let tempPath = path
      for (let key in obj) {
        if (obj[key]) {
          tempPath = `${tempPath}&${key}=` + obj[key]
        }
      }
      return Promise.resolve(tempPath)
    },

    // 点击媒体
    async openMedia(item) {
      console.log('触发')
      if (item.type == 'images') {
        ImagePreview({
          images: [item.upload_url],
          closeable: true
        })
      } else if (item.type == 'video') {
        // item.media_url = 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
        window.open(item.media_url)
      } else if (item.type == 'we_applets') {
        await openMiniProgram({ appid: item.appid, path: item.url })
        // console.log('打开小程序')
      } else if (item.type == 'web_page') {
        window.open(item.url)
      }
    },

    //封面图片过滤
    showImgCover(url, type) {
      if (!url && type == 'web_page') {
        return require('@/assets/img/suggestion/default_2x.svg')
      }
      return url
    },
    // 状态过滤器
    statusFormat(status) {
      let arr = ['可发送', '已发送', '已结束（未发送）', '已结束（已发送）']
      return arr[status - 0]
    }
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/style/bottom_btn.less');
.task-detail {
  background-color: #f5f5f5;
  height: calc(100% - 46px);
  .task-header {
    padding: 12px 15px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .task-title {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 4px;
    }
    .task-time {
      line-height: 16px;
      font-size: 12px;
      font-weight: 300;
      color: #999999;
    }

    .hearder-operation {
      display: flex;
      align-items: center;
      min-width: 82px;
      padding-left: 5px;

      .status-text {
        line-height: 24px;
        font-size: 12px;
        color: #999;
      }
    }
  }

  .task-detail-content {
    padding: 14px 15px;
    background-color: #fff;
    min-height: calc(100% - 30px);

    .content-text {
      line-height: 20px;
      background-color: #f5f7fa;
      font-size: 14px;
      color: #9b9b9b;
      padding: 14px 12px;
      margin-bottom: 14px;
      border-radius: 4px;
    }

    .media-detail-box {
      padding-top: 15px;
    }
    .detail-media {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 3px;
      // justify-content: space-between;
    }
    .detail-media-item {
      width: 33.3%;
    }
    .detail-media-box-out {
      // padding: 8px;
      // border: 1px solid #D8DCE6;
      width: 108px;
      height: 108px;
      border-radius: 4px;
      margin: 0 auto;
      margin-bottom: 10px;
      overflow: hidden;
      display: flex;
      position: relative;

      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 36px;
        height: 36px;
        // opacity: 0.8;
        z-index: 5;
      }
    }
    .detail-media-box {
      width: 100%;
      height: 100%;
    }

    .media-item-detail {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
      text-align: center;
      background: rgba(0, 0, 0, 0.4);
      // display: flex;
      // align-items: center;
      // .media-title {
      //   line-height: 20px;
      //   font-size: 14px;
      //   color: #000;
      // }

      // .media-describe {
      //   line-height: 17px;
      //   font-size: 12px;
      //   color: #999;
      // }
    }
  }

  .send-btn {
    padding: 7px 12px;
    border-radius: 5px;
    // width: ;
    background-color: #1773fa;
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      clear: both;
    }
    .btn-icon {
      float: left;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      // display: block;
      float: left;
      margin-top: 1px;
      margin-left: 5px;
      line-height: 18px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
</style>
<style lang="less">
.task-detail .van-button--small {
  border-radius: 5px;
  // height: 28px;
  padding-left: 11px;
  padding-right: 12px;
  // .van-button__icon {
  //   padding-top: 2px;
  //   margin-right: 1px;
  // }
  // .van-icon__image {
  //   height: 18px;
  //   width: 18px;
  // }
  // .van-button__icon + .van-button__text {
  //   display: block;
  //   // line-height: 18px;
  //   margin-top: 1px;
  //   line-height: 32px;
  // }
  .van-button__icon {
    // padding-top: 2px;
    // margin-right: 1px;
    height: 18px;
    width: 18px;
  }
  .van-icon__image {
    height: 100%;
    width: 100%;
  }
  .van-button__icon + .van-button__text {
    display: block;
    line-height: 18px;
    // margin-top: 1px;
  }
}
</style>
